<template>
 
  <div>分享记录管理</div>
</template>

<script>
export default {};
</script>

<style></style>
